import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router";
import { registerUser } from "../redux/authSlice";
import { addBotPersona, getBotPersona, setBotPersonaStatus } from "../redux/botSlice";
import axios from "axios";
import BotPersonaForm from "../components/BotPersonaForm";
import { ValidatorForm } from "react-material-ui-form-validator";

export default function EditPersona() {
  const [botPersonaData, setBotPersonaData] = useState({});
  const [editBotPersonaStatus, setEditBotPersonaStatus] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, isLoggedIn } = useSelector((state) => state.auth);
  const { botPersonaStatus, botPersona } = useSelector((state) => state.bot);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("update bot persona with:");
    console.log(botPersonaData)
    setEditBotPersonaStatus("pending");
    axios.post("editBotPersona", botPersonaData)
        .then((response) => {
            console.log("editBotPersona Complete: ")
            console.log(response);
            setEditBotPersonaStatus("complete");
            //dispatch(setBotPersonaStatus('idle'));
            navigate("/bots");
        })
        .catch((err) => {
            console.log("editBotPersona Error: ")
            console.log(err);
            setEditBotPersonaStatus("failed");
        })
    ;
  };

  useEffect(() => {
    setBotPersonaData(botPersona);
    dispatch(setBotPersonaStatus('idle'));
}, [dispatch]);


    return (
        <ValidatorForm
            onSubmit={handleSubmit}
        >
            <input type="hidden" name="id" value={botPersona.id} />
            <BotPersonaForm botPersonaData={botPersonaData} setBotPersonaData={setBotPersonaData} />
            <Button
                type="submit"
                sx={{
                width: "100%",
                margin: "1.5rem 0",
                padding: "12px 0",
                borderRadius: "28px",
                }}
                variant="contained"
                color="primary"
            >
                {editBotPersonaStatus === "pending" ? (
                <CircularProgress size={24} sx={{ color: "#FFF" }} />
                ) : (
                "Update Bot Persona"
                )}
            </Button>
        </ValidatorForm>
    );
}
