import { Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

const PrivateTest = () => {
    const auth = useSelector((state) => state.auth);
    return (
        <Typography>This is /private ! {process.env.NODE_ENV} Test:{process.env.REACT_APP_API_BASE_URL} Name: {auth.user.name}</Typography>
    );
}

export default PrivateTest;