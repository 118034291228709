import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router";
import { registerUser } from "../redux/authSlice";
import { addBotPersona } from "../redux/botSlice";
import axios from "axios";
import BotPersonaForm from "../components/BotPersonaForm";
import { ValidatorForm } from "react-material-ui-form-validator";

export default function AddPersona() {
  const [botPersonaData, setBotPersonaData] = useState({});
  const [addBotPersonaStatus, setAddBotPersonaStatus] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, isLoggedIn } = useSelector((state) => state.auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("addPersona submitted: ");
    console.log(botPersonaData)
    setAddBotPersonaStatus("pending");
    axios.post("addBotPersona", botPersonaData)
        .then((response) => {
            console.log("addBotPersona Complete: ")
            console.log(response);
            setAddBotPersonaStatus("complete");
            navigate('/bots');
        })
        .catch((err) => {
            console.log("addBotPersona Error: ")
            console.log(err);
            setAddBotPersonaStatus("failed");
        })
    ;
  };

  useEffect(() => {
    setBotPersonaData({
        prompt: '',
        model_temperature: 0.5,
        example_conversation: '',
        model_name: 'text-divinci-003',
        model_max_tokens: 60,
        model_temperature: 0.5,
        model_top_p: 0.3,
        model_frequency_penalty: 0.5,
        model_presence_penalty: 0.0,
        model_best_of: 1,
    });
  }, [dispatch]);


    return (
        <ValidatorForm
            onSubmit={handleSubmit}
        >
            <BotPersonaForm botPersonaData={botPersonaData} setBotPersonaData={setBotPersonaData} />
            <Button
                type="submit"
                sx={{
                width: "100%",
                margin: "1.5rem 0",
                padding: "12px 0",
                borderRadius: "28px",
                }}
                variant="contained"
                color="primary"
            >
                {addBotPersonaStatus === "pending" ? (
                <CircularProgress size={24} sx={{ color: "#FFF" }} />
                ) : (
                "Add Bot Persona"
                )}
            </Button>
        </ValidatorForm>
    );
}
