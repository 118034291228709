import { Routes, Route, Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SmartToyIcon from '@mui/icons-material/SmartToy';

import { authSlice, logout } from "../redux/authSlice";

export default function LeftNavigation() {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    return (
        <>
            <List>
                <ListItem button key="home" component={Link} to="/home">
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText>Home</ListItemText>
                </ListItem>
            </List>
            <Divider />
            <List>
                {auth.isLoggedIn &&
                <ListItem button key="bots" component={Link} to="/bots">
                    <ListItemIcon>
                        <SmartToyIcon />
                    </ListItemIcon>
                    <ListItemText>Bots</ListItemText>
                </ListItem>
                }
                {auth.isLoggedIn &&
                <ListItem button key="private-alt" component={Link} to="/private-alt">
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText>Private Alt</ListItemText>
                </ListItem>
                }
                {auth.isLoggedIn &&
                <ListItem button key="private" component={Link} to="/private">
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText>Private</ListItemText>
                </ListItem>
                }
                {!auth.isLoggedIn &&
                <ListItem button key="login" component={Link} to="/login">
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText>Login</ListItemText>
                </ListItem>
                }
                {!auth.isLoggedIn &&
                <ListItem button key="register" component={Link} to="/register" >
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText>Register</ListItemText>
                </ListItem>
                }
                {auth.isLoggedIn &&
                <ListItem button key="logout" component={Link} onClick={() => {dispatch(logout());} } >
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </ListItem>
                }
            </List>
        </>
    )
}