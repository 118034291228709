import {
  Routes,
  Route,
  Outlet,
  Link
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { setAuth } from "./redux/authSlice";

import Layout from "./components/Layout";
import PrivateOutlet from "./components/PrivateOutlet";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Missing from "./pages/Missing";
import SecureTest from "./pages/SecureTest";
//import { useTheme } from "@mui/material/styles";
//import { styled, useTheme, createTheme, ThemeProvider } from '@mui/system'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import './App.css';
import Login from "./pages/Login";
import PrivateTest from "./pages/PrivateTest";
import Bots from "./pages/Bots";
import AddPersona from "./pages/AddPersona";
import EditPersona from "./pages/EditPersona";
import ChatWithPersona from "./pages/ChatWithPersona";

function App() {
  const customTheme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: "#f18d17",
        contrastText: "#6a0dad"
      },
      secondary: {
        main: '#1d2eaa',
      },
      alternateTextColor: {
        main: '#e27e0f',
      }
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    /*if ("auth" in localStorage) {
      const auth = JSON.parse(localStorage.getItem("auth"));
      //auth = JSON.parse(localStorage.getItem("auth"));
      axios.defaults.headers.common["authorization"] = `Bearer ${auth.token}`;
      if (auth) {
        console.log("setting auth");
        dispatch(setAuth(auth));
      }
    }*/
  }, [dispatch]);

  return (

    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="bots" element={<PrivateRoute><Bots /></PrivateRoute>} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="private-alt" element={<SecureTest />} />
        <Route path="private" element={<PrivateRoute><PrivateTest /></PrivateRoute>} />
        <Route path="addPersona" element={<PrivateRoute><AddPersona /></PrivateRoute>} />
        <Route path="editPersona" element={<PrivateRoute><EditPersona /></PrivateRoute>} />
        <Route path="chatWithPersona/:id" element={<PrivateRoute><ChatWithPersona /></PrivateRoute>} />
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>

  );
}

export default App;
