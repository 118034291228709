import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "idle",
  isLoggedIn: false,
  user: {},
  profile: {},
  userStatus: "idle",
  users: [],
  login: false,
};

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (userData) => {
    console.log('get csrf');
    let data = null;
    await axios.get('/sanctum/csrf-cookie')
    .then((response) => {
      console.log('got csrf');
      axios.post("/auth/login", userData)
      .then((response) => {
        console.log('got login');
        console.log(data);
        data = response;

        axios.get('/botPersonas')
        .then((response) => {
          console.log('bots', response);
        });
      }, [data]);
    }, [data]);
    //const { data } = await axios.post("/auth/login", userData);

    console.log('fin csrf');
    console.log('fin data');
    console.log(data);
    return data;
  }
);

/*export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (userData) => {
    await axios.get('/sanctum/csrf-cookie')
    const { data } = await axios.post("/auth/login", userData);

    console.log(data);
    return data;
  }
);*/


export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (userData) => {
    const { data } = await axios.post("/auth/register", userData);
    return data;
  }
);

export const getProfile = createAsyncThunk("auth/getProfile", async (id) => {
  const { data } = await axios.get("profile/" + id);
  return data;
});

export const getUsers = createAsyncThunk("auth/getUsers", async () => {
  const { data } = await axios.get("/auth/users/");
  return data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      console.log("reducer set auth:");
      console.log(action.payload);
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    logout: (state, action) => {
      localStorage.clear();
      state.isLoggedIn = false;
      state.user.name = '';
      state.user.email = '';
      state.user.id = '';
      axios.defaults.headers.common["authorization"] = null;
      const { data } = axios.post("/auth/logout");
      console.log('logged out');
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [loginUser.fulfilled]: (state, action) => {
      console.log('loginUser.fullfilled');
      console.log(action.payload);
      // store login response to "auth" in localStorage
      /*localStorage.setItem(
        "auth",
        JSON.stringify(action.payload)
      );

      // update app state to reflect new auth data
      state.user.name = action.payload.user.name;
      state.user.email = action.payload.user.email;
      state.user._id = action.payload.user._id;*/
      state.status = "success";
      state.isLoggedIn = true;
    },
    [loginUser.rejected]: (state, action) => {
      state.status = "failed";
      state.isLoggedIn = false;
    },

    [registerUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [registerUser.fulfilled]: (state, action) => {
      console.log(action.payload);
      localStorage.setItem(
        "auth",
        JSON.stringify(action.payload)
      );
      // update app state to reflect new auth data
      state.user.name = action.payload.user.name;
      state.user.email = action.payload.user.email;
      state.user._id = action.payload.user._id;
      state.status = "success";
      state.isLoggedIn = true;
    },
    [registerUser.rejected]: (state, action) => {
      state.status = "failed";
      state.isLoggedIn = false;
    },

    [getProfile.pending]: (state, action) => {
      state.status = "loading";
    },
    [getProfile.fulfilled]: (state, action) => {
      state.status = "success";
      state.profile = action.payload.profile;
    },
    [getProfile.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getUsers.pending]: (state, action) => {
      state.userStatus = "loading";
    },
    [getUsers.fulfilled]: (state, action) => {
      state.userStatus = "success";
      state.users = action.payload.response.users;
    },
    [getUsers.rejected]: (state, action) => {
      state.userStatus = "failed";
    },
  },
});

export default authSlice.reducer;
export const { setAuth, logout } = authSlice.actions;
