import { Typography } from "@mui/material";

export default function Home() {
    return (
        <Typography>
            Web application home<br/>
            Environment: {process.env.NODE_ENV}<br/>
            Base URL:{process.env.REACT_APP_API_BASE_URL}<br/>
        </Typography>
    );
}