import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

const initialState = {
  botPersonasStatus: "idle",
  botPersonas: [],
  addBotPersonaStatus: "idle",
  botPersonaStatus: "idle",
  botPersona: [],
};

export const getBotPersonas = createAsyncThunk(
    "botSlice/getBotPersonas",
    async (userData) => {
        const { data } = await axios.get("botPersonas");
        return data;
    }
);

export const getBotPersona = createAsyncThunk(
  "botSlice/getBotPersona",
  async (id) => {
    const { data } = await axios.get("botPersona/" + id);
    return data;
  }
);


export const botSlice = createSlice({
  name: "botSlice",
  initialState,
  reducers: {
    setPaymentTerm: (state, action) => {
      state.paymentTerm = action.payload;
    },
    setBotPersonaStatus: (state, action) => {
      state.botPersonaStatus = action.payload;
    },
  },
  extraReducers: {
    [getBotPersonas.pending]: (state, action) => {
      state.botPersonasStatus = "loading";
    },
    [getBotPersonas.fulfilled]: (state, action) => {
      state.botPersonas = action.payload;
      state.botPersonasStatus = "success";
    },
    [getBotPersonas.rejected]: (state, action) => {
        state.botPersonasStatus = "failed";
    },
    [getBotPersona.pending]: (state, action) => {
      state.botPersonaStatus = "loading";
    },
    [getBotPersona.fulfilled]: (state, action) => {
      console.log('got bot persona');
      console.log(action.payload);
      state.botPersona = action.payload;
      state.botPersonaStatus = "success";
      //const navigate = useNavigate();
      //navigate('/editPersona');
    },
    [getBotPersona.rejected]: (state, action) => {
        state.botPersonaStatus = "failed";
    },
  },
});

export default botSlice.reducer;
export const { setPaymentTerm, setBotPersonaStatus } = botSlice.actions;
