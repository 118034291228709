import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import botSlice from "./botSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    bot: botSlice,
  },
});
