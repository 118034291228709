import React, { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import { FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, Slider, Typography } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

export default function BotPersonaForm(props) {
    const aiModels = [
        'text-davinci-003',
        'text-davinci-002',
        'text-currie-001',
        'text-babbage-001',
        'text-ada-001',
        'code-davinci-002',
        'code-cushman-001',
    ];

    return (
        <>
            <InputLabel mt={2}>Bot Name:</InputLabel>
            <TextValidator
                sx={{ width: "100%", margin: "1rem 0", bgcolor: "#fff" }}
                name="name"
                label="Bot Name"
                onChange={(e) =>
                    props.setBotPersonaData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                }))
                }
                value={props.botPersonaData.name || ''}
                validators={['required']}
                errorMessages={['this field is required']}
                required
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <HomeIcon />
                        </InputAdornment>
                    ),
                }}
            />

            <InputLabel mt={2}>Bot Prompt:</InputLabel>
            <TextValidator
                sx={{ width: "100%", margin: "1rem 0", bgcolor: "#fff" }}
                name="prompt"
                label="Bot Prompt"
                multiline
                rows={6}
                onChange={(e) =>
                    props.setBotPersonaData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                }))
                }
                value={props.botPersonaData.prompt || ''}
                validators={['required']}
                errorMessages={['this field is required']}
                required
            />

            <InputLabel mt={2}>Example Conversation:</InputLabel>
            <TextValidator
                sx={{ width: "100%", margin: "1rem 0", bgcolor: "#fff" }}
                name="example_conversation"
                label="Example Conversation"
                multiline
                rows={6}
                onChange={(e) =>
                    props.setBotPersonaData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                }))
                }
                value={props.botPersonaData.example_conversation || ''}
                validators={['required']}
                errorMessages={['this field is required']}
                required
            />
            <Typography mt={2} mb={1}>AI Model:</Typography>
            <FormControl fullWidth>
                <Select
                    name="model_name"
                    label="AI Model Name"
                    value={props.botPersonaData.model_name || ''}
                    onChange={(e) =>
                        props.setBotPersonaData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                    }))
                    }
                >
                    {aiModels.map((model) => (<MenuItem key={model} value={model}>{model}</MenuItem>))}
                </Select>
            </FormControl>
            <Typography mt={2} mb={1}>Max Tokens:</Typography>
            <FormControl fullWidth>
                <Slider
                    name="model_max_tokens"
                    value={props.botPersonaData.model_max_tokens || 0.5 }
                    min={0}
                    max={1000}
                    step={1}
                    valueLabelDisplay="auto"
                    onChange={(e) =>
                        props.setBotPersonaData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                    }))
                    }
                />
            </FormControl>
            <Typography mt={2} mb={1}>Temperature:</Typography>
            <FormControl fullWidth>
                <Slider
                    name="model_temperature"
                    value={props.botPersonaData.model_temperature || 0.5 }
                    min={0}
                    max={1}
                    step={0.1}
                    valueLabelDisplay="auto"
                    onChange={(e) =>
                        props.setBotPersonaData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                    }))
                    }
                />
            </FormControl>
            <Typography mt={2} mb={1}>Top P:</Typography>
            <FormControl fullWidth>
                <Slider
                    name="model_top_p"
                    value={props.botPersonaData.model_top_p || 0.5 }
                    min={0}
                    max={1}
                    step={.01}
                    valueLabelDisplay="auto"
                    onChange={(e) =>
                        props.setBotPersonaData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                    }))
                    }
                />
            </FormControl>

            <Typography mt={2} mb={1}>Frequency Penalty</Typography>
            <FormControl fullWidth>
                <Slider
                    name="model_frequency_pentalty"
                    value={props.botPersonaData.model_frequency_pentalty || 0.5 }
                    min={0}
                    max={2}
                    step={.01}
                    valueLabelDisplay="auto"
                    onChange={(e) =>
                        props.setBotPersonaData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                    }))
                    }
                />
            </FormControl>

            <Typography mt={2} mb={1}>Presence Penalty</Typography>
            <FormControl fullWidth>
                <Slider
                    name="model_presence_pentalty"
                    value={props.botPersonaData.model_presence_pentalty || 0.5 }
                    min={0}
                    max={2}
                    step={.01}
                    valueLabelDisplay="auto"
                    onChange={(e) =>
                        props.setBotPersonaData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                    }))
                    }
                />
            </FormControl>

            <Typography mt={2} mb={1}>Best Of</Typography>
            <FormControl fullWidth>
                <Slider
                    name="model_best_of"
                    value={props.botPersonaData.model_best_of || 0.5 }
                    min={1}
                    max={20}
                    step={1}
                    valueLabelDisplay="auto"
                    onChange={(e) =>
                        props.setBotPersonaData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                    }))
                    }
                />
            </FormControl>
        </>
    );
}