import { Button, Grid, Typography } from "@mui/material";
import { getBotPersonas, getBotPersona, setBotPersonaStatus } from "../redux/botSlice";
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { PanoramaSharp } from "@mui/icons-material";
import axios from "axios";

export default function Bots() {
    const dispatch = useDispatch();
    const { botPersonasStatus } = useSelector((state) => state.bot);
    const { botPersonas } = useSelector((state) => state.bot);
    const { botPersona, botPersonaStatus } = useSelector((state) => state.bot);
    const [ selectedBot, setSelectedBot] = useState({});
    const navigate = useNavigate();

    const gridCols = [
        {field: 'name', headerName: 'Name', width: 200},
        {field: 'model_name', headerName: 'Model', width: 120},
        {field: 'model_temperature', headerName: 'Temp', width: 120},
        {field: 'model_max_length', headerName: 'Max Len', width: 120},
        {field: 'model_top_p', headerName: 'Top P', width: 120},
        {field: 'model_frequency_pentalty', headerName: 'Freq. Penalty', width: 120},
        {field: 'model_presence_pentalty', headerName: 'Presence Penalty', width: 120},
        {field: 'model_best_of', headerName: 'Best Of', width: 120},
        {field: 'interactions', headerName: 'Interactions', width: 120},
        {field: 'prompt', headerName: 'Prompt', width: 400},
    ];

    useEffect(() => {
        console.log('bot page useeffect');
        console.log(botPersonaStatus, botPersona);
        dispatch(getBotPersonas());
        setSelectedBot(false);
        dispatch(setBotPersonaStatus('idle'));
    }, [dispatch]);

    const editPersona = (id) => {
        dispatch(getBotPersona(id));
    };

    const clearConversation = (id) => {
        axios.post("clearConversation" , {bot_persona_id: selectedBot.id})
            .then((response) => {
                console.log("clearConversation Complete: ")
                console.log(response);

            })
            .catch((err) => {
                console.log("clearConversation Error: ")
                console.log(err);
                //setAddUserConversationItemStatus("failed");
            })
        ;
    };
    if (botPersonaStatus == 'success') {
        return <Navigate to="/editPersona" />;
    } else {
        return (
            <>
            <Typography>
            Bots
            </Typography>
            {botPersonasStatus === 'success' &&
                <Grid container>
                    <Grid item xs={12}>
                        <DataGrid pageSize={5} autoHeight={true} rowsPerPageOptions={[5, 10, 20]} columns={gridCols} rows={botPersonas} onSelectionModelChange={
                            (ids) => {
                                // filter bot personas into selected row(s)
                                const selectedIDs = new Set(ids);
                                const selectedRows = botPersonas.filter((row) =>
                                    selectedIDs.has(row.id),
                                );
                                if (selectedRows.length > 0) {
                                    console.log(selectedRows);
                                    setSelectedBot(selectedRows[0]);
                                } else {
                                    setSelectedBot(false);
                                }
                            }
                        } />
                    </Grid>
                </Grid>
            }
            <Link to="/addPersona"><Button variant="outlined" >Add Persona</Button></Link>
            {selectedBot.name &&
            <Link to={"/chatWithPersona/" + selectedBot.id}><Button variant="outlined" >Chat With Persona</Button></Link>
            }
            {selectedBot.name &&
            <Button variant="outlined" onClick={() => {
                editPersona(selectedBot.id);
            }}>Edit Persona</Button>
            }
            {selectedBot.name &&
            <Button variant="outlined" onClick={() => {
                clearConversation(selectedBot.id);
            }}>Clear Conversation</Button>
            }
            </>
        );
    }
}