import { Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

// alternative way to secure a route
const SecureTest = () => {
    const auth = useSelector((state) => state.auth);
    if (!auth.isLoggedIn) {
        return <Navigate to="/login" />;
    }
    return (
        <Typography>
            I am /secure<br/>
            Name: {auth.user.name}
        </Typography>
    );
}

export default SecureTest;