import { Avatar, Button, CircularProgress, Grid, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
   useParams
  } from "react-router-dom";
import { getBotPersona } from "../redux/botSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import HomeIcon from "@mui/icons-material/Home";

export function ConversationItem(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={1}>
        <Avatar alt={props.data.from_user ? 'User: ' : props.data.persona.name + ': '}>
          {props.data.from_user ? 'User: ' : props.data.persona.name + ': '}
        </Avatar>
      </Grid>
      <Grid item xs={1}>
        <Typography>
          {props.data.from_user ? 'User: ' : props.data.persona.name + ': '}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography>
          {props.data.message}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default function ChatWithPersona(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const { botPersonaStatus, botPersona } = useSelector((state) => state.bot);
    const chatBodyRef = React.createRef();
    const [ conversationItems, setConversationItems] = useState([]);
    const [ hasMore, setHasMore] = useState(true);
    const [conversationItemData, setConversationItemData] = useState({});
    const [addUserConversationItemStatus, setAddUserConversationItemStatus] = useState('idle');
    const style = {
        height: 30,
        border: "1px solid green",
        margin: 6,
        padding: 8
      };

    const scrollChat = () => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }

    const fetchMoreData = () => {
        conversationItemData.bot_persona_id = params.id;
        /*if (conversationItems.length >= 500) {
          setHasMore(false);
          return;
        }*/
        axios.post("getLastConversationItems", conversationItemData)
            .then((response) => {
                console.log("getLastConversationItems Complete: ")
                console.log(response);
                //setConversationItems(conversationItems.concat(response.data));
                setConversationItems(response.data);

                /*setConversationItems((prev) => ({
                    ...prev,
                    ...response.data
                }));*/

                //setAddUserConversationItemStatus("complete");
                //dispatch(setBotPersonaStatus('idle'));
                //navigate("/bots");
            })
            .catch((err) => {
                console.log("getLastConversationItems Error: ")
                console.log(err);
                //setAddUserConversationItemStatus("failed");
            })
        ;
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        console.log("send chat message");
        console.log(conversationItemData);
        setAddUserConversationItemStatus("pending");
        axios.post("addUserConversationItem", conversationItemData)
            .then((response) => {
                console.log("addUserConversationItem Complete: ")
                console.log(response);
                setAddUserConversationItemStatus("complete");
                fetchMoreData();
                //dispatch(setBotPersonaStatus('idle'));
                //navigate("/bots");
            })
            .catch((err) => {
                console.log("addUserConversationItem Error: ")
                console.log(err);
                setAddUserConversationItemStatus("failed");
            })
        ;
      };

    useEffect(() => {
      console.log('chat items');
        console.log(conversationItems);
        dispatch(getBotPersona(params.id));
        setConversationItemData((prev) => ({
            ...prev,
            bot_persona_id: params.id,
        }));
        console.log('chat item', params.id);

        //scrollChat();
        fetchMoreData();
        setInterval(() => {
          console.log('in timeout fetch more data');
          fetchMoreData();
        }, 1000);
    }, [dispatch, params]);

    //for (let i = 0; i < 1000; i++) {
      //fetchMoreData();
    //}
    //<Box sx={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 150, overflowY: 'scroll', border: '1px solid black'}} ref={chatBodyRef}>

    return (
        <>

        <InfiniteScroll
          inverse={true}
          style={{ display: "flex", flexDirection: "column-reverse", position: 'fixed', top: 60, right: 0, left: 0, bottom: 150, overflow: 'auto', border: '1px solid black' }} //To put endMessage and loader to the top.
          dataLength={conversationItems.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}

          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {conversationItems.map((conversationItem, index) => (
            <ConversationItem data={conversationItem} index={index} />
          ))}
        </InfiniteScroll>

        <Box sx={{position: 'fixed', bottom: 0, right: 0, left: 0, border: '1px solid black', height: 150}}>
          <ValidatorForm
              onSubmit={handleSubmit}
          >
            <InputLabel mt={2}>Message Body:</InputLabel>
            <TextValidator
                sx={{ width: "100%", margin: "1rem 0", bgcolor: "#fff" }}
                name="message"
                label="Message"
                onChange={(e) =>
                  setConversationItemData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                }))
                }
                value={conversationItemData.message || ''}
                validators={['required']}
                errorMessages={['this field is required']}
                required
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <HomeIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                type="submit"
                variant="contained"
                color="primary"
            >
                {addUserConversationItemStatus === "pending" ? (
                <CircularProgress size={24} sx={{ color: "#FFF" }} />
                ) : (
                "Send Message"
                )}
            </Button>
          </ValidatorForm>
        </Box>
        </>
    );
}